import { createWebHistory, createRouter } from "vue-router";
import DynamicPage from "../components/DynamicPage";
import Home from '../components/Home'
import Donate from '../components/DonatePage'
import RegularDonate from '../components/RegularDonatePage'
import DownloadsPage from '../components/DownloadsPage'
import KeepingInTouchPage from '../components/KeepingInTouch'
import LatestAppealPage from '../components/LatestAppealPage'

const routes = [
  {
    path: "",
    name: "Root",
    component: Home,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: '/Downloads',
    name: "Downloads",
    component: DownloadsPage
  },
  {
    path: '/givenow',
    name: "Give Now",
    component: Donate
  },
  {
    path: '/make-donation',
    name: "Make a Donation",
    component: Donate
  },
  {
    path: '/regular-donation',
    name: "Regular Donation",
    component: RegularDonate
  },
  {
    path: '/donateRegularly',
    name: "Make a Regular Donation",
    component: RegularDonate
  },
  {
    path: '/Keeping-in-touch',
    name: "Keeping In Touch",
    component: KeepingInTouchPage
  },
  {
    path: '/Latest-appeal',
    name: "Latest Appeal",
    component: LatestAppealPage
  },
  {
    path: "/:page",
    name: "About",
    component: DynamicPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;