<template>
    <router-link :to="button.url">
        <img :src="'/images/' + button.image" alt="">
    </router-link>
</template>

<script>
export default {
    props: {
        button: Object
    }
}
</script>