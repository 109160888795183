<template>
    <div class="button item px-3 border-r-2 last:border-0 border-black relative cursor-pointer hover:bg-slate-300 transition-colors">
        <router-link :to="'/' + title.replaceAll(' ', '-')" class="w-full">
            <div class="font-normal">
                {{ title }}
            </div>
        </router-link>
        <div class="menu absolute top-6 left-0 pt-2 bg-slate-50 z-10 hidden min-w-full">
            <ul class="text-center leading-7 list-none p-0">
                <template  v-for="option in subTitles" :key="option.index">
                    <router-link :to="'/' + option.replaceAll(' ', '-')">
                        <li class="hover:bg-slate-300 transition-colors cursor-pointer py-1 px-6 whitespace-nowrap font-normal">
                            {{ option }}
                        </li>
                    </router-link>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menu: Array
    },
    computed: {
        title() {
            if(this.menu !== undefined){
                return this.menu[0]
            } else {
                return ''
            }
        },
        subTitles() {
            if(this.menu !== undefined){
                let menu = [...this.menu];
                menu.shift()
                return menu
            } else {
                return []
            }
        }
    }
}
</script>

<style scoped>
    .button:hover .menu {
        display: block;
    }
</style>