<template>
    <div class="container m-auto">
        <h1>Downloads</h1>
        <p>To become a Member of the SVR Charitable Trust please email michael.howard@svrtrust.org.uk or call 01562 757940</p>
        <div v-for="category in downloads" :key="category.index">
            <h2 class="my-4">{{ category.title }}</h2>
            <p>{{ category.description }}</p>
            <div v-for="download in category.downloads" :key="download.index" class="flex items-center flex-col sm:flex-row">
                <div class="w-1/4 md:w-5/12 flex items-center">
                    <img :src="'https://svrtrust.org.uk/uploaded_images/' + download.image" alt="download.description" class="h-32 m-4">
                    <div class="hidden md:inline-block">{{ download.description }}</div>
                </div>
                <div class="w-3/4 md:w-5/12 text-center md:text-left">
                    <a :href="'https://www.svrtrust.org.uk/downloads/' + download.name">{{ download.name }}</a>
                </div>
                <div class="hidden md:block w-2/12">
                    {{ download.size }}
                </div>
            </div>
            <hr class="border border-grey-400">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            downloads: {"title": "",
                "description": "",
                "downloads": [
                    {
                    "image": "",
                    "description": "",
                    "name": "",
                    "size": "",
                    }
                ]
            }
        }
    },
    mounted() {
        fetch('https://svrtrust.org.uk/api/downloads.php')
            .then(response => response.json())
            .then(data => {
                this.downloads = data
            })
    }
}
</script>