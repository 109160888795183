<template>
    <div class="container m-auto max-w-5xl">
        <h1 class="mb-5">Keeping in touch</h1>
        <p class="my-3">Our supporters are very important to us, and we don't want to send you unwanted mailings or email messages. However, we really do want to be able to keep in touch to update you about the work we're involved with, and our appeals, as well as invite you to special thank you event at the SVR.</p>
        <p class="my-3">If you're happy for us to contact you, please tick the appropriate boxes below. </p>
        <p class="my-3">My name is</p>
        <div class="flex items-center">
            First name <input type="text" class="border border-black p-2 m-2" v-model="form.billingName">
            Surname <input type="text" class="border border-black p-2 m-2" v-model="form.billingSName">
        </div>
        <p class="mb-3">and I´m happy for you to contact me:</p>
        <p><input type="checkbox" v-model="form.emailCheckbox"> by email  (this is a very quick and cost efficient way for us to keep in touch)</p>
        <div v-if="form.emailCheckbox">
            <div class="flex items-center">
                Email address: <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingEmail">*<br>
            </div>
            <p class="my-3">* is required</p>
        </div>
        <p><input type="checkbox" v-model="form.addressCheckbox"> by post</p>
        <div v-if="form.addressCheckbox">
            Address:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingAddr1">*
            <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingAddr2">
            <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingAddr3"><br>
            City:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingCity">*<br>
            County:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingCounty">*<br>
            Country:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingCountry">*<br>
            Postcode:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingPostcode">*<br>
            * is required
        </div>
        <p><input type="checkbox" v-model="form.phoneCheckbox"> by telephone (we'll only use the telephone to confirm details or arrangements, not for marketing purposes.</p>
        <div v-if="form.phoneCheckbox">
            <div class="flex items-center">
                Phone number:  <br><input type="text" class="border border-black p-2 m-2 w-64" v-model="form.billingPhoneNumber">*<br>
            </div>
            <p>* is required</p>
        </div>
        <button class="border border-black p-2 m-2 w-64" @click="submitForm">Submit</button>
        <p>You can change how we contact you at any time by re-submitting this information form, or by phoning us on 01562 757940.</p>
        <p>See full details of our <a href="https://www.svrtrust.org.uk/index.php?page=Privacy%20Policy">Privacy Policy</a></p>
	</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                billingName: '',
				billingSName: '',
				addressCheckbox: false,
				emailCheckbox:false,
				phoneCheckbox: false,
				billingAddr1: '',
				billingAddr2: '',
				billingAddr3: '',
				billingCity: '',
				billingCounty: '',
				billingCountry: '',
				billingPostcode: '',
				billingEmail:'',
				billingPhoneNumber:''
            },
        }
    }, 
    methods: {
        submitForm() {
            // Send details to server
            const formData = new FormData()
            for(let [key, value] of Object.entries(this.form)) {
                formData.set(key, value)
            }

            fetch('https://www.svrtrust.org.uk/common/saveGdpr.php', {
                method: 'POST',
                body: formData
            }).then(response => response.text())
            .then(message => {
                alert(message)
                this.$router.push('/Home')
            })
        }
    }
}
</script>