<template>
    <footer>
        <div class="justify-center py-4 hidden md:flex">
            <template v-for="menu in menus" :key="menu.index">
                <DropDown :menu="menu" />
            </template>
        </div>
        <div class="flex flex-wrap items-center justify-center mb-16">
            <img src="https://www.svrtrust.org.uk/images/logo.png" class="h-16 m-6">
            <img src="https://www.svrtrust.org.uk/images/blue-white.jpg" class="h-16 m-6">
            <img src="https://www.svrtrust.org.uk/images/frsb-logo.jpg" class="h-16 m-6">
            <img src="https://ciof.org.uk/IoF/media/assets/ciof_logo_green_RGB.png" class="h-16 m-6">
        </div>
    </footer>
</template>

<script>
import DropDown from './DropDown';

export default {
    name: "footer-component",
    components: {
        DropDown
    },
    data() {
        return {
            menus: [
                ['Downloads'],
                ['Privacy policy'],
                ['Contact us'],
                ['Charity number: 1092723'],
            ]
        }
    }
}
</script>