<template>
    <div class="container m-auto max-w-6xl">
    <div class="flex justify-between flex-col-reverse md:flex-row">
        <h3 class="text-3xl py-3">I would like to make an online single donation to the SVR Charitable Trust</h3>
        <img src="https://www.svrtrust.org.uk/images/blue.svg" class="h-16">
    </div>
        <p>An unrestricted donation means we can put your money to work where it is needed most. However, you can restrict your donation to one of the projects areas in the drop down below.</p>
        <select v-model="form.donationUse" class="border border-black p-2 my-3 text-xl w-full">
            <option value="General Unrestricted Funds">General Unrestricted Funds</option>
            <option value="Home &amp; Dry Appeal">Home &amp; Dry Appeal</option>
            <option value="Fight Back Fund – Emergency general funding">Fight Back Fund – Emergency general funding</option>
            <option value="Heritage Apprenticeship Scheme">Heritage Apprenticeship Scheme</option>
            <option value="Wheelchair access">Wheelchair access</option>
            <option value="Bridgnorth Development Fund">Bridgnorth Development Fund</option>
            <option value="Infrastructure">Infrastructure</option>
            <option value="Permanent Way">Permanent Way</option>
            <option value="Diesel projects">Diesel projects</option>
            <option value="Rolling stock restoration">Rolling stock restoration</option>
            <option value="Education and Exhibitions">Education and Exhibitions</option>
            <option value="Hagley Hall Locomotive">Hagley Hall Locomotive</option>
            <option value="Future Fund">Future Fund</option>
        </select> 
        <p style="display:none;"><input type="checkbox" id="donationInMemory" onchange="nameOfPerson()">Please tick if this is a donation in memory</p>
        <p id="nameOfPerson" style="display:none;">Name of the person you wish to remember <input type="text" class="bigInput" id="rememberName"></p>
		
        <h2 class="text-3xl my-3">Billing Details</h2>
		<div class="md:flex">
            <div class="md:w-1/2">
                First name: <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingFirstName" ref="billingFirstName"><span class="text-xl px-3 text-red-600">*</span><br>
                Surname:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingSecondName" ref="billingSecondName"><span class="text-xl px-3 text-red-600">*</span><br>
                Address:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingAddr1" ref="billingAddr1"><span class="text-xl px-3 text-red-600">*</span>
                <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingAddr2" ref="billingAddr2">
                <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingAddr3" ref="billingAddr3"><br>
                City:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingCity" ref="billingCity"><span class="text-xl px-3 text-red-600">*</span><br>
                County:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingCountry" ref="billingCountry"><span class="text-xl px-3 text-red-600">*</span><br>
                Country:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingCounty" ref="billingCounty"><span class="text-xl px-3 text-red-600">*</span><br>
                Postcode:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingPostcode" ref="billingPostcode"><span class="text-xl px-3 text-red-600">*</span><br>
                Phone number:  <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.billingPhoneNumber" ref="billingPhoneNumber"><span class="text-xl px-3 text-red-600">*</span><br>
                Email address: <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.email" ref="email"><span class="text-xl px-3 text-red-600">*</span><br>
                Confirm email address: <br><input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="confirmEmail" ref="confirmEmail"><span class="text-xl px-3 text-red-600">*</span><br>
                <span class="text-xl px-3 text-red-600">*</span> is required
            </div>

            <div class="md:w-1/2">
                <h3 class="text-3xl my-3" ref="extraDonationAmount">I would like to make a donation of</h3>
                <h3>£<input type="text" class="border border-black p-2 my-3 text-xl w-3/4" v-model="form.extraDonationAmount" @keypress="isNumber(event)"></h3>
                <p class="my-3">We'd like to keep in touch to update you from time to time on the SVR and its appeals. Your details are safe with us, and we will never share them with anyone outside the SVR family. The other two members of the SVR family are Severn Valley Railway (Holdings) plc, and the Severn Valley Railway Company Ltd. You can change your mind at any time about how we contact you.</p>
                <h3 class="text-3xl my-3" ref="contact">I am happy for you to contact me by</h3>
                <div id="contactText" >
                    <input type="checkbox" name="contactMe"  v-model="form.contactPost" @click="noContact = false"> Post<br>
                    <input type="checkbox" name="contactMe" v-model="form.contactMeEmail" @click="noContact = false"> Email<br>
                    <input type="checkbox" name="contactMe" v-model="form.contactTelephone" @click="noContact = false"> Telephone<br>
                    <input type="checkbox" name="contactMe"  v-model="noContact"
                        @click="form.contactPost = false; form.contactMeEmail = false; form.contactTelephone = false;"
                    > Please don't contact me - I understand I will not receive updates on the SVR Charitable Trust's work, or invitations to special thank you events.<br>
                </div>
                <br>
                <p class="my-3">Boost your donation by 25p of Gift Aid for every £1 you donate. Gift Aid is reclaimed by the charity from the tax you pay in the current tax year. Your address is needed to identify you as a current UK taxpayer. In order to Gift Aid your donation, you must tick the box.</p>
                <img src="https://www.svrtrust.org.uk/images/giftaid.png" style="float:right;">
                <p class="my-3" ref="giftAid"><input type="checkbox" v-model="form.giftAid" @click="noGiftAid = false" > Yes, I want to Gift Aid this donation and any donations I make in the future or have made in the past four years. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.</p>
                <p class="my-3"><input type="checkbox" v-model="noGiftAid" @click="form.giftAid = false"> No, I don’t want to Gift Aid my donation.</p>
                <h3 class="text-3xl my-3">
                    Total Donation: £{{ form.extraDonationAmount }}
                    <button class="block px-6 py-3 my-3 rounded-xl" @click="payNow">Pay Now</button>
                </h3>
            </div>
        </div>
    </div>

    <!-- purchase form -->
    <form action="https://secure.worldpay.com/wcc/purchase" method="POST" ref="detailsForm">

    <!--Makes it test mode-->
        <input type="hidden" name="testMode" value="0"> <!--  <input type="hidden" name="testMode" value="100"> -->

        <input type="hidden" name="instId" value="1269176">
        <input type="hidden" name="cartId" :value="orderId">
        <input type="hidden" name="amount" :value="form.extraDonationAmount">
        <input type="hidden" name="currency" value="GBP">
        <input type="hidden" name="name" :value="form.billingFirstName + ' ' + form.billingSecondName">
        <input type="hidden" name="address1" :value="form.billingAddr1">
        <input type="hidden" name="address2" :value="form.billingAddr2">
        <input type="hidden" name="address3" :value="form.billingAddr3">
        <input type="hidden" name="town" :value="form.billingCity">
        <input type="hidden" name="region" :value="form.billingCounty">
        <input type="hidden" name="postcode" :value="form.billingPostcode">
        <input type="hidden" name="country" value="UK">
        <input type="hidden" name="tel" :value="form.billingPhoneNumber">
        <input type="hidden" name="email" :value="form.email">

    </form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                product: 'Single donation',
                billingFirstName: '',
                billingSecondName: '',
                billingAddr1: '',
                billingAddr2: '',
                billingAddr3: '',
                billingAddr4: '',
                billingCity: '',
                billingCounty: '',
                billingCountry: '',
                billingPostcode: '',
                billingPhoneNumber: '',
                email: '',
                extraDonation: 'no',
                extraDonationAmount: '',
                contactPost: false,
                contactEmail: false,
                contactTelephone: false,
                giftAid: false,
                rememberName: '',
                donationUse: "General Unrestricted Funds"
            },
            confirmEmail: 'lukejwhite@gmail.com',
            missingInformation: false,
            noContact: false,
            noGiftAid: false,
            orderId: 0
        }
    },
    methods: {
        payNow: function() {
            // Check emails match.
            if( this.form.email !== this.confirmEmail ) {
                alert('Emails do not match')
                this.$refs.email.classList.add('attention')
                this.$refs.confirmEmail.classList.add('attention')
                return
            }

            // Check for missing Information
            const fieldsToCheck = ['billingFirstName', 'billingSecondName', 'billingAddr1', 'billingCity', 'billingCounty', 'billingPostcode', 'email', 'confirmEmail']
            fieldsToCheck.forEach(this.checkBox)

            if (this.missingInformation) {
                alert('Required information is missing')
                this.missingInformation = false
                return
            }

            //check email is correct
            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) {  // eslint-disable-line
				alert('Invalid Email Address');
				return
			}

            //check donation amount is decimal with 2dp
            this.form.extraDonationAmount = parseFloat(this.form.extraDonationAmount).toFixed(2)
            if( this.form.extraDonationAmount === 'NaN' ) {
                this.form.extraDonationAmount = 0.00
            }

            //check for minimum and maximum donation
            if (parseFloat(this.form.extraDonationAmount) < 10) {
                alert("Please enter a value greater than £10.")
                return
            }
            if (parseFloat(this.form.extraDonationAmount) > 5000) {
                alert("The maximum amount the website can take in a single payment is £5000. For donations larger than this please call our team on 01562 757940.")
                return
            }

            //check contact me options have been selected
            if (!(this.form.contactPost || this.form.contactMeEmail || this.form.contactTelephone || this.noContact)) {
                alert('Please select contact method')
                this.$refs.contact.classList.add('attention')
                return
            }

            // check GiftAid
            if (!(this.form.giftAid || this.noGiftAid)) {
                alert('Please select Gift Aid preference')
                this.$refs.giftAid.classList.add('attention')
                return
            }

            // Send donation details to be stored
            const formData = new FormData()
            for(let [key, value] of Object.entries(this.form)) {
                if (value===true) {
                    value = 'yes'
                } 
                if (value === false) {
                    value = 'no'
                }
                formData.set(key, value)
            }

            fetch('https://www.svrtrust.org.uk/ajax/saveAddress.php', {
                method: 'POST',
                body: formData
            }).then(response => response.text())
            .then(orderId => {
                this.orderId = orderId
                this.$refs.detailsForm.submit()
            })

        },
        checkBox: function(e) {
            if( this.form[e] == '' ) {
                this.$refs[e].classList.add('attention')
                this.missingInformation = true
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true;
            }
        }  
    },
}
</script>

<style scoped>
    .attention[type='text'] {
        border: 4px solid red;
    }

    p.attention, h3.attention {
        color: red;
        font-weight: bold;
    }
</style>