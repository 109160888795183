<template>
    <div v-for="layout in page" :key="layout.index" class="leading-6 max-w-5xl m-auto">
        <layout4444 v-if="layout.layout_layout === '4444'" :layout="layout" />
        <layout244 v-if="layout.layout_layout === '244'" :layout="layout" />
        <layout442 v-if="layout.layout_layout === '442'" :layout="layout" />
        <layout424 v-if="layout.layout_layout === '424'" :layout="layout" />
        <layout333 v-if="layout.layout_layout === '333'" :layout="layout" />
        <layout34 v-if="layout.layout_layout === '34'" :layout="layout" />
        <layout43 v-if="layout.layout_layout === '43'" :layout="layout" />
        <layout32 v-if="layout.layout_layout === '32'" :layout="layout" />
        <layout23 v-if="layout.layout_layout === '23'" :layout="layout" />
        <layout22 v-if="layout.layout_layout === '22'" :layout="layout" />
        <layout1 v-if="layout.layout_layout === '1'" :layout="layout" />
    </div>
</template>
<script>
import Layout4444 from '../layouts/Layout4444'
import Layout244 from '../layouts/Layout244'
import Layout442 from '../layouts/Layout442'
import Layout424 from '../layouts/Layout424'
import Layout333 from '../layouts/Layout333'
import Layout34 from '../layouts/Layout34'
import Layout43 from '../layouts/Layout43'
import Layout32 from '../layouts/Layout32'
import Layout23 from '../layouts/Layout23'
import Layout22 from '../layouts/Layout22'
import Layout1 from '../layouts/Layout1'

export default {
    data() {
        return {
            page: []
        }
    },
    components: {
        Layout4444,
        Layout244,
        Layout442,
        Layout424,
        Layout333,
        Layout34,
        Layout43,
        Layout32,
        Layout23,
        Layout22,
        Layout1,
    },
    mounted() {
        this.getPage()
    },
    methods: {
        getPage() {
            fetch('https://svrtrust.org.uk/api/page.php?page=' + this.$route.path.substring(1) )
                .then(response => response.json())
                .then(data => {
                    data.forEach(layout => {
                        console.log(layout)
                        layout.html1 = layout.html1.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                        layout.html2 = layout.html2.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                        layout.html3 = layout.html3.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                        layout.html4 = layout.html4.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                        layout.html5 = layout.html5.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                        layout.html6 = layout.html6.replaceAll('../uploaded_images/', 'https://www.svrtrust.org.uk/uploaded_images/')
                    })
                    this.page = data;
                })
        }
    },
 watch: {
    '$route' () {
      this.getPage();
    }
  },
}
</script>
