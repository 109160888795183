<template>
    <div class="container m-auto max-w-5xl">
        <h1 class="my-8">Ten years making a tremendous difference</h1>
        <div class="flex flex-col-reverse md:flex-row ">
            <div class="md:w-8/12 md:pr-16">
                <p>
                    The Severn Valley Railway Charitable Trust turns 10 this year and it’s a great time to celebrate the incredible £8.74m donations that have been raised for the Railway over the last decade including 6.54m through the Trust and £2.2m in emergency grants directly to the Railway.
                </p>
                <p>
                    Thanks to your incredible support, funding raised through the Trust in grants and donations is supporting vital infrastructure, rolling stock and educational projects such as the restoration of 4930 Hagley Hall, Falling Sands Viaduct, the apprenticeship scheme and – following the success of the most recent £475,000 appeal – desperately-needed improvements to Bridgnorth Locomotive Works.
                </p>
            </div>
            <div class="md:w-4/12 items-center">
                <img src="/images/time.jpg" alt="">
            </div>
        </div>
        <div>
            <h1 class="mt-8">Ten ways you’ve helped to make a difference</h1>
            <p>To celebrate the Charitable Trust’s 10th anniversary year, here are 10 ways the generosity of donors has made a big difference...</p>
            <ol>
                <li><strong>Locomotive restoration:</strong> Generous donors have helped bring a variety of important locomotives back into steam, including 4930 Hagley Hall, along with diesel locomotives D2960 (Silver Spoon) and Class 11 12099.</li>
                <li><strong>Carriage restoration:</strong> Just under £600,000 has helped fund the restoration of 20 carriages, including the historic LNER Gresley set of teak carriages, along with the restoration of what is believed to be the first wheelchair-access buffet car.</li>
                <li><strong>Infrastructure restoration:</strong> Regular givers have helped to enable a number of infrastructural projects such as £70,000 raised for Borle Viaduct.</li>
                <li><strong>Track re-laying:</strong> It costs £150,000 per year to re-lay a half-mile of track which is essential to avoid wear and tear on rolling stock. £388,000 of funding has been donated in the last five years to support Permanent Way projects.</li>
                <li><strong>Diesel Depot equipment:</strong> A £31,000 investment has been made to keep the SVR’s heritage diesel fleet on track.</li>
                <li><strong>Small but vital projects:</strong> Small projects make a big difference too. For example, £5,000 was raised to replace the rotten Bewdley South bracket signal. The SVR is fortunate to possess two rare wooden-post bracket signals. The funds raised help keep this rare and historic style of early GWR signal in operation.</li>
                <li><strong>Apprentice training:</strong> 14 apprentices have benefitted from training and development worth £21,000 each per year. The scheme is critical for preserving vital heritage skills and receives £30,000 per year from regular gifts.</li>
                <li><strong>Educational projects:</strong> A variety of educational projects have been supported by regular givers, for example £8,000 raised for the Journey Through Time exhibition at the Engine House in Highley.</li>
                <li><strong>Emergency funding:</strong> Regular givers have helped the Trust provide emergency funding, such as £150,000 for the county boundary and Sterns which were in urgent need.</li>
                <li><strong>Endowment fund:</strong> We’re building the SVR’s investment fund toward £10m to provide security for the Railway’s future.</li>
            </ol>
        </div>
        <div>
            <h1 class="text-center my-8">
                Could you help us support rolling stock, infrastructure and educational projects across the Railway by donating £10 per month in celebration of our 10th anniversary year?  
            </h1>
            <p>When you sign up to make a monthly gift, you automatically join our Patrons’ Club. You’ll receive invitations to thank-you events at the Railway and a special lapel pin badge to recognise your support.</p>
            <router-link to="/regular-donation">
                <button class="m-auto block my-8">Sign up</button>
            </router-link>
        </div>
    </div>
</template>