<template>
    <div class="container m-auto">
        <router-link to="/Latest-appeal">
            <img src="/images/home-banner.jpg" class="m-auto">
        </router-link>
        <div class="container max-w-5xl m-auto">
            <section class="my-12">
                <p class="text-center">
                    The Severn Valley Railway Charitable Trust supports vital rolling stock, infrastructure and educational projects to protect and preserve the Railway so it can be enjoyed for generations to come.
                </p>
                <p class="text-center">
                    Over ten years, the incredible support we’ve received from generous donors and grant makers has enabled a diverse range of crucial projects to happen. The SVR’s flagship locomotive, Hagley Hall, is now on its way back into steam; the brand-new Diesel Depot is built; the majestic Falling Sands Viaduct restored; 14 heritage apprentices trained and much, much more.
                </p>
            </section>
            <div class="flex justify-center mt-6 flex-wrap md:flex-nowrap">
                <div v-for="button in heroButtons" :key="button.index">
                    <HeroButtons :button="button" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeroButtons from './HeroButtons'

export default {
    name: 'Page-Body',
    components: {
        HeroButtons
    },
    data() {
        return {
            heroButtons : [
                {image: "support-us.jpg", url: "/How-to-support-us"},
                {image: "latest.jpg", url: "/Latest-appeal"},
                {image: "gift.jpg", url: "/Gifts-in-Wills"},
                {image: "news.jpg", url: "/News"},
            ],
        }
    },
}
</script>