<template>
    <div class="flex container m-auto max-w-5xl py-6 justify-around flex-col items-center lg:flex-row lg:items-baseline">
        <div class="mb-8 lg:mb-0">
            <router-link to="/Home">
                <img src="https://www.svrtrust.org.uk/images/logo.png" class="w-48">
            </router-link>
        </div>
        <div class="sm:flex items-end hidden">
            <template v-for="menu in menus" :key="menu.index">
                <DropDown :menu="menu" />
            </template>
        </div>
        <div class="flex items-center mt-4 lg:mt-0">
            <router-link to="/make-donation">
                <img src="https://www.svrtrust.org.uk/images/donate_now.svg" class="h-16 md:h-12">
            </router-link>
        </div>
    </div>
    <div class="sm:hidden absolute top-6 left-6" @click="mobile = true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-8"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
    </div>
    <div class="fixed w-full h-full top-0 left-0" v-if="mobile" @click="mobile = false"></div>
    <div class="fixed top-0 left-0 w-3/4 h-full bg-slate-100 shadow-xl transform overflow-scroll" v-if="mobile" @click="mobile = false">
        <router-link to="/Home">
            <img src="https://www.svrtrust.org.uk/images/logo.png" class="w-32 m-auto mt-8 mb-4">
        </router-link>
        <div v-for="categories in menus" :key="categories.index">
            <div v-for="(link, index) in categories" :key="link.index" class="p-3 text-center">
                <router-link :to="'/' + link.replaceAll(' ', '-')">
                    <div :class="{ 'text-xl text-svr font-bold': index === 0 }">{{ link }}</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown from './DropDown'

export default {
    name: 'Page-Header',
    components: {
        DropDown
    },
    data() {
        return {
            menus: [],
            mobile: false
        }
    },
    mounted() {
        fetch('https://svrtrust.org.uk/api/menu.php')
            .then(response => response.json())
            .then(data => {
                this.menus = data;
            })
    }
}
</script>