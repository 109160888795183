<template>
    <div class="container m-auto md:flex mb-3">
        <div class="md:w-1/4">
            <h1 class="text-3xl mx-3 my-1">{{ layout.title1 }}</h1>
            <h1 class="text-2xl mx-3 my-1">{{ layout.sTitle1 }}</h1>
            <div v-html="layout.html1" class="mx-3 my-1"/>
        </div>
        <div class="md:w-3/4">
            <h1 class="text-3xl mx-3 my-1">{{ layout.title2 }}</h1>
            <h1 class="text-2xl mx-3 my-1">{{ layout.sTitle2 }}</h1>
            <div v-html="layout.html2" class="mx-3 my-1"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        layout: Object
    },
    name: "Layout-22"
}
</script>